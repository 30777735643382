import { find } from 'lodash';
import React, { useCallback, useMemo, useContext, useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { Drawer } from '@mui/material';

import { useTranslateMessage } from '../../../components/localization';
import { useAppData } from '../../../util/app-data/AppData';
import { AppContext, useAppContext } from '../../../util/app-provider/context';

import { useEntityContext } from '../../../util/entity-provider/context';
import { isProduction } from '../../../util/isProduction';
import { mapChildsAndUpdatePaths } from './util';
import { useMediaQueries } from '../../../hooks/useMediaQueries';
import SidebarContent from './SidebarContent';

const NewSidebar = ({ pathPrefix }) => {
  const translateMessage = useTranslateMessage();

  const { isMobile } = useMediaQueries();
  const { isSidebarOpen, toggleSidebar } = useContext(AppContext);
  const { appConfig } = useAppContext();

  const { entityRef } = useEntityContext();
  const { menuConfig: initialMenuConfig } = useAppData();
  const { menuConfig, setCurrentFeature } = useAppContext();

  const location = useLocation();
  const navigate = useNavigate();

  const preparedConfig = useMemo(() => {
    if (!menuConfig) return [];

    const isProd = isProduction();

    const reducer = (accumulator, configItem) => {
      if (!configItem || (isProd && configItem.hideInProduction)) {
        return accumulator;
      }

      if (configItem.type === 'group-options') {
        const mappedChilds = configItem.children.map(configItem => {
          if (!configItem.children?.length) return configItem;

          const childrenWithHomePageOnTop = mapChildsAndUpdatePaths(configItem, translateMessage);

          return {
            ...configItem,
            children: childrenWithHomePageOnTop,
          };
        });
        return [...accumulator, { id: 'divider' }, ...mappedChilds, { id: 'divider' }];
      }

      if (!configItem.children?.length) {
        return [...accumulator, configItem];
      }

      const childrenWithHomePageOnTop = mapChildsAndUpdatePaths(configItem, translateMessage);

      return [
        ...accumulator,
        {
          ...configItem,
          children: childrenWithHomePageOnTop,
        },
      ];
    };

    const filteredConfig = menuConfig.reduce(reducer, []);

    return filteredConfig;
  }, [menuConfig, translateMessage]);

  const determineCurrentFeature = useCallback(() => {
    const flattenedMenuConfig = preparedConfig?.reduce?.((acc, item) => {
      if (item?.children?.length) {
        return [...acc, ...item.children?.map(child => ({ ...child, path: `${child.path}` }))];
      }
      return [...acc, item];
    }, []);

    const matched = matchPath(
      { path: `${pathPrefix || ''}${appConfig?.isEntitySpecific ? '/:entityRef' : ''}/:path/*` },
      location.pathname
    );

    const targetPath =
      `${matched?.params?.path || ''}${matched?.params?.['*'] ? `/${matched?.params?.['*']}` : ''}` || '';

    const targetItem = find(flattenedMenuConfig, { path: `/${targetPath}` });
    setCurrentFeature(targetItem);
  }, [appConfig?.isEntitySpecific, location, setCurrentFeature, pathPrefix, preparedConfig]);

  useEffect(() => {
    determineCurrentFeature();
  }, [determineCurrentFeature]);

  const handleMenuItemClick = useCallback(
    menuItemPath => {
      const destination = `${pathPrefix || ''}${appConfig?.isEntitySpecific ? `/${entityRef}` : ''}${menuItemPath}`;
      if (destination !== location.pathname) navigate(destination);
    },
    [appConfig?.isEntitySpecific, entityRef, location.pathname, navigate, pathPrefix]
  );

  const amIActive = useCallback(
    me =>
      location.pathname.startsWith(
        `${pathPrefix || ''}${appConfig?.isEntitySpecific ? `/${entityRef}` : ''}${me.path}`
      ),
    [appConfig?.isEntitySpecific, entityRef, location.pathname, pathPrefix]
  );

  const sidebarContentProps = {
    isMobile,
    handleMenuItemClick,
    toggleSidebar,
    initialMenuConfig,
    preparedConfig,
    amIActive,
  };

  return (
    <>
      {isMobile ? (
        <Drawer
          open={isSidebarOpen}
          onClose={toggleSidebar}
          sx={{ width: '267px' }}
          PaperProps={{
            sx: {
              backgroundColor: 'transparent',
              overflow: 'hidden',
              borderRadius: '0 10px 10px 0',
            },
          }}
        >
          <SidebarContent {...sidebarContentProps} />
        </Drawer>
      ) : (
        <SidebarContent {...sidebarContentProps} />
      )}
    </>
  );
};

export default NewSidebar;
