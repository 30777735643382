import common from '../common';
import errors from './errors';
import themes from './theme';
import features from './features';

export default {
  ...common,
  mobileTicket: 'Elektronisk følgeseddel',
  ...features,
  save: 'Gem',
  username: 'Brugernavn',
  password: 'Adgangskode',
  login: 'Log ind',
  required: 'Obligatorisk',
  light: 'Lys',
  dark: 'Mørk',
  rememberMe: 'Husk mig',
  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Fortrolighedspolitik',
  customer: 'Kunde',
  project: 'Projekt',
  isBillable: 'Kan kontaktes',
  dispatchDateTime: 'Dato',
  orderId: 'Bestille',
  ticketId: 'Følgeseddel',
  location: 'Lokation',
  vehicleTypeName: 'Køretøj',
  product: 'Produkt',
  products: 'Produkt',
  salesTax: 'moms',
  totalPrice: 'Total',
  extendedPrice: 'Forøget pris',
  destination: 'Bestemmelsessted',
  productId: 'Produkt ID',
  statusCode: 'Status',
  vehicleTypeId: 'Køretøj ID',
  locationId: 'Lokation ID',
  customerId: 'Kunde ID',
  projectId: 'Projekt ID ',
  dispatchTime: 'Tid',
  showAll: 'Vis alt',
  hideAll: 'Gem alt',
  undoChanges: 'slet ændringer',
  resetColumns: 'Reset kolonner',
  displayRowCount: `Vis {count, plural, 
    =0 {0 rows} 
    =1 {# row} 
    other {# rows} 
  }`,
  columnFilters: 'Kolonne filter',
  visible: 'Synligt',
  hidden: 'Gemt',
  topSection: 'Øverste Sektion',
  itemDetail: 'Ordrerække detaljer',
  quantity: 'Antal',
  unloadingMethod: 'Aflæsnings type',
  time: 'Tid',
  eventStatus: 'Status',
  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'EA',
  LTR_abbr: 'L',
  YDQ_abbr: 'CY',
  GLL_abbr: 'GAL',
  MTQ_text: 'Kubikmeter',
  M3_text: 'Kubikmeter',
  EA_text: 'Hver',
  LTR_text: 'Liter',
  YDQ_text: 'Kubikværfter',
  GLL_text: 'Liter',
  ACR_text: 'Hektar',
  HUR_text: 'Time',
  INH_text: 'Inches',
  FOT_text: 'Fod',
  YRD_text: 'Gård',
  MMT_text: 'Millimeter',
  CMT_text: 'Centimeter',
  MTR_text: 'Måler',
  SMI_text: 'Mile',
  KMT_text: 'Kilometer',
  INK_text: 'Firkantet',
  FTK_text: 'Kvadratfod',
  YDK_text: 'Firkantet',
  MIK_text: 'Firkantet',
  CMK_text: 'Firkantet',
  MTK_text: 'Kubikmeter',
  KMK_text: 'Firkantet',
  INQ_text: 'Kubik',
  MMQ_text: 'Kubikmillimeter',
  FTQ_text: 'Kubikfod',
  CMQ_text: 'Kubikcentimeter',
  OZA_text: 'Væske',
  PT_text: 'Pint',
  QT_text: 'Kvart',
  MLT_text: 'Milliliter',
  ONZ_text: 'Ounce',
  LBR_text: 'Pund',
  STN_text: 'Ton',
  GRM_text: 'Gram',
  KGM_text: 'Kilogram',
  TNE_text: 'Ton',
  PS_text: 'PSI',
  MPA_text: 'Megapascal',
  C56_text: 'Newton',
  C62_text: 'En',
  NL_text: 'belastning',
  MT_text: 'Måtte',
  P1_text: 'Procent',
  CEL_text: 'Grader',
  FAH_text: 'Grad',
  BLL_text: 'Tønde',
  CLT_text: 'Centilitre',
  DLT_text: 'Decilitre',
  GLI_text: 'Gallon',
  '2U_text': 'Megagram',
  SA_text: 'Sæk',
  '60_text': 'Procent',
  BG_text: 'Taske',
  SEC_text: 'Anden',
  MIN_text: 'Minut',
  '64_text': 'Pund',
  SP_text: 'Hyldepakke',
  MMK_text: 'Kvadratmillimeter',
  MTN_text: 'Metric Ton',
  GT_text: 'Gigaton',
  '/NL_text': 'Pr. belastning',
  '/EA_text': 'Pr. mængde',
  '/H/ + h_text': 'Pr. time',
  '/YQ/ + YDQ_text': 'Pr. kubikyard',
  '/MQ/ + MTQ_text': 'Pr. kubikmeter',
  '/MN/ + MIN_text': 'Pr. minut',
  '/MT_text': 'Per meter',
  DSG_text: 'Dosis',
  '/C_text': 'Pr. 100 vægt',
  '64_2_text': 'Specifik',
  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    =1 {1 m\u00B3}
    other {# m\u00B3}
  }`,
  M3: `{value, plural,
    =0 {0 m\u00B3}
    =1 {1 m\u00B3}
    other {# m\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 Liters}
    =1 {1 Liter}
    other {# Liters}
  }`,
  TO: `{value, plural,
    =0 {0 Tons}
    =1 {1 Ton}
    other {# Tons}
  }`,
  ITEM: `{value, plural,
    =0 {0 Items}
    =1 {1 Item}
    other {# Items}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 CY}
    =1 {1 CY}
    other {# CY}
  }`,
  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    =1 {1 Cubic Yard}
    other {# Cubic Yards}
  }`,
  GLL: `{value, plural,
    =0 {0 Gallons}
    =1 {1 Gallon}
    other {# Gallons}
  }`,
  EA: `{value, plural,
    =0 {0 Each}
    =1 {1 Each}
    other {# Each}
  }`,
  MIN: `{value, plural,
    =0 {0 Minutes}
    =1 {1 Minute}
    other {# Minutes}
  }`,
  ACR: `{value, plural,
    =0 {0 Acres}
    =1 {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Hours}
    =1 {1 Hour}
    other {# Hours}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    =1 {1 Inch}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Feet}
    =1 {1 Foot}
    other {# Feet}
  }`,
  YRD: `{value, plural,
    =0 {0 Yards}
    =1 {1 Yard}
    other {# Yards}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimetres}
    =1 {1 Millimetre}
    other {# Millimetres}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimetres}
    =1 {1 Centimetre}
    other {# Centimetres}
  }`,
  MTR: `{value, plural,
    =0 {0 Metres}
    =1 {1 Metre}
    other {# Metres}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    =1 {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometres}
    =1 {1 Kilometre}
    other {# Kilometres}
  }`,
  INK: `{value, plural,
    =0 {0 Square Inches}
    =1 {1 Square Inch}
    other {# Square Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Square Feet}
    =1 {1 Square Foot}
    other {# Square Feet}
  }`,
  YDK: `{value, plural,
    =0 {0 Square Yards}
    =1 {1 Square Yard}
    other {# Square Yards}
  }`,
  MIK: `{value, plural,
    =0 {0 Square Miles}
    =1 {1 Square Mile}
    other {# Square Miles}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimetres}
    =1 {1 Square Centimetre}
    other {# Square Centimetres}
  }`,
  MTK: `{value, plural,
    =0 {0 Cubic Metres}
    =1 {1 Cubic Metre}
    other {# Cubic Metres}
  }`,
  KMK: `{value, plural,
    =0 {0 Square Kilometres}
    =1 {1 Square Kilometre}
    other {# Square Kilometres}
  }`,
  INQ: `{value, plural,
    =0 {0 Cubic Inches}
    =1 {1 Cubic Inch}
    other {# Cubic Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimetres}
    =1 {1 Cubic Millimetre}
    other {# Cubic Millimetres}
  }`,
  FTQ: `{value, plural,
    =0 {0 Cubic Feet}
    =1 {1 Cubic Foot}
    other {# Cubic Feet}
  }`,
  CMQ: `{value, plural,
    =0 {0 Cubic Centimetres}
    =1 {1 Cubic Centimetre}
    other {# Cubic Centimetres}
  }`,
  OZA: `{value, plural,
    =0 {0 Fluid Ounces}
    =1 {1 Fluid Ounce}
    other {# Fluid Ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    =1 {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    =1 {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Millilitres}
    =1 {1 Millilitre}
    other {# Millilitres}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ounces}
    =1 {1 Ounce}
    other {# Ounces}
  }`,
  LBR: `{value, plural,
    =0 {0 Pounds}
    =1 {1 Pound}
    other {# Pounds}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    =1 {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Grams}
    =1 {1 Gram}
    other {# Grams}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilograms}
    =1 {1 Kilogram}
    other {# Kilograms}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    =1 {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    =1 {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    =1 {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Square Millimetre}
    =1 {1 Newton Per Square Millimetre}
    other {# Newtons Per Square Millimetre}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    =1 {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Loads}
    =1 {1 Load}
    other {# Loads}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    =1 {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Percent}
    =1 {1 Percent}
    other {# Percent}
}`,
  CEL: `{value, plural,
    =0 {0 Degrees Celsius}
    =1 {1 Degree Celsius}
    other {# Degrees Celsius}
}`,
  FAH: `{value, plural,
    =0 {0 Degrees Fahrenheit}
    =1 {1 Degree Fahrenheit}
    other {# Degrees Fahrenheit}
}`,
  BLL: `{value, plural,
    =0 {0 Barrels}
    =1 {1 Barrel}
    other {# Barrels}
}`,
  CLT: `{value, plural,
    =0 {0 Centilitres}
    =1 {1 Centilitre}
    other {# Centilitres}
}`,
  DLT: `{value, plural,
    =0 {0 Decilitres}
    =1 {1 Decilitre}
    other {# Decilitres}
}`,
  GLI: `{value, plural,
    =0 {0 Gallons}
    =1 {1 Gallon}
    other {# Gallons}
}`,
  '2U': `{value, plural,
    =0 {0 Megagrams}
    =1 {1 Megagram}
    other {# Megagrams}
}`,
  SA: `{value, plural,
    =0 {0 Sacks}
    =1 {1 Sack}
    other {# Sacks}
}`,
  60: `{value, plural,
    =0 {0 Percent Weight}
    =1 {1 Percent Weight}
    other {# Percent Weight}
}`,
  BG: `{value, plural,
    =0 {0 Bags}
    =1 {1 Bag}
    other {# Bags}
}`,
  SEC: `{value, plural,
    =0 {0 Seconds}
    =1 {1 Second}
    other {# Seconds}
}`,
  64: `{value, plural,
    =0 {0 Pounds per square inch - Gauge}
    =1 {1 Pound per square inch - Gauge}
    other {# Pounds per square inch - Gauge}
}`,
  SP: `{value, plural,
    =0 {0 Shelf Packages}
    =1 {1 Shelf Package}
    other {# Shelf Packages}
}`,
  MMK: `{value, plural,
    =0 {0 Kvadratmillimeter}
    one {1 kvadratmillimeter}
    other {# kvadratmillimeter}
  }`,
  MTN: `{value, plural,
    =0 {0 metriske tons}
    one {1 metrisk ton}
    other {# metriske tons}
  }`,
  GT: `{value, plural,
    =0 {0 Gigaton}
    one {1 Gigaton}
    other {# Gigaton}
  }`,
  '/NL': `{value, plural,
    =0 {0 pr. belastning}
    one {1 pr. belastning}
    other {# pr. belastning}
  }`,
  '/EA': `{value, plural,
    =0 {0 pr. mængde}
    one {1 pr. mængde}
    other {# pr. mængde}
  }`,
  '/H/ + h': `{value, plural,
    =0 {0 i timen}
    one {1 i timen}
    other {# i timen}
  }`,
  '/YQ/ + YDQ': `{value, plural,
    =0 {0 pr. kubik}
    one {1 pr. kubik}
    other {# pr. kubik}
  }`,
  '/MQ/ + MTQ': `{value, plural,
    =0 {0 pr. kubikmeter}
    one {1 pr. kubikmeter}
    other {# pr. kubikmeter}
  }`,
  '/MN/ + MIN': `{value, plural,
    =0 {0 pr. minut}
    one {1 i minuttet}
    other {# i minuttet}
  }`,
  '/MT': `{value, plural,
    =0 {0 pr meter}
    one {1 pr meter}
    other {# pr meter}
  }`,
  DSG: `{value, plural,
    =0 {0 dosering}
    one {1 dosering}
    other {# dosering}
  }`,
  '/C': `{value, plural,
    =0 {0 pr 100 vægt}
    one {1 pr 100 vægt}
    other {# pr 100 vægt}
  }`,
  '64_2': `{value, plural,
    =0 {0 Bestemt}
    one {1 Bestemt}
    other {# Bestemt}
  }`,
  LTR_short: `{value, plural,
  =0 {0 L}
  one {1 L}
  other {# L}
}`,
  TO_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# Tn}
}`,
  ITEM_short: `{value, plural,
  =0 {0 Items}
  one {1 Item}
  other {# Items}
}`,
  GLL_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  EA_short: `{value, plural,
  =0 {0 EA}
  one {1 EA}
  other {# EA}
}`,
  MIN_short: `{value, plural,
  =0 {0 MIN}
  one {1 MIN}
  other {# MIN}
}`,
  ACR_short: `{value, plural,
  =0 {0 AC}
  one {1 AC}
  other {# AC}
}`,
  HUR_short: `{value, plural,
  =0 {0 HR}
  one {1 HR}
  other {# HR}
}`,
  INH_short: `{value, plural,
  =0 {0 IN}
  one {1 IN}
  other {# IN}
}`,
  FOT_short: `{value, plural,
  =0 {0 FT}
  one {1 FT}
  other {# FT}
}`,
  YRD_short: `{value, plural,
  =0 {0 YD}
  one {1 YD}
  other {# YD}
}`,
  MMT_short: `{value, plural,
  =0 {0 MM}
  one {1 MM}
  other {# MM}
}`,
  CMT_short: `{value, plural,
  =0 {0 CM}
  one {1 CM}
  other {# CM}
}`,
  MTR_short: `{value, plural,
  =0 {0 M}
  one {1 M}
  other {# M}
}`,
  SMI_short: `{value, plural,
  =0 {0 Miles (ST MI)}
  one {1 Mile ((ST MI)}
  other {# Miles ((ST MI)}
}`,
  KMT_short: `{value, plural,
  =0 {0 KM}
  one {1 KM}
  other {# KM}
}`,
  INK_short: `{value, plural,
  =0 {0 SQ IN}
  one {1 SQ IN}
  other {# SQ IN}
}`,
  FTK_short: `{value, plural,
  =0 {0 FT2}
  one {1 FT2}
  other {# FT2}
}`,
  YDK_short: `{value, plural,
  =0 {0 SQ YD}
  one {1 SQ YD}
  other {# SQ YD}
}`,
  MIK_short: `{value, plural,
  =0 {0 SQ MI}
  one {1 SQ MI}
  other {# SQ MI}
}`,
  CMK_short: `{value, plural,
  =0 {0 CM2}
  one {1 CM2}
  other {# CM2}
}`,
  MTK_short: `{value, plural,
  =0 {0 M3}
  one {1 M3}
  other {# M3}
}`,
  KMK_short: `{value, plural,
  =0 {0 KM2}
  one {1 KM2}
  other {# KM2}
}`,
  INQ_short: `{value, plural,
  =0 {0 CU IN}
  one {1 CU IN}
  other {# CU IN}
}`,
  MMQ_short: `{value, plural,
  =0 {0 MM3}
  one {1 MM3}
  other {# MM3}
}`,
  FTQ_short: `{value, plural,
  =0 {0 FT3}
  one {1 FT3}
  other {# FT3}
}`,
  CMQ_short: `{value, plural,
  =0 {0 CM3}
  one {1 CM3}
  other {# CM3}
}`,
  OZA_short: `{value, plural,
  =0 {0 FL OZ}
  one {1 FL OZ}
  other {# FL OZ}
}`,
  PT_short: `{value, plural,
  =0 {0 PT}
  one {1 PT}
  other {# PT}
}`,
  QT_short: `{value, plural,
  =0 {0 QT}
  one {1 QT}
  other {# QT}
}`,
  MLT_short: `{value, plural,
  =0 {0 mL}
  one {1 mL}
  other {# mL}
}`,
  ONZ_short: `{value, plural,
  =0 {0 OZ}
  one {1 OZ}
  other {# OZ}
}`,
  LBR_short: `{value, plural,
  =0 {0 LB}
  one {1 LB}
  other {# LB}
}`,
  STN_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  GRM_short: `{value, plural,
  =0 {0 G}
  one {1 G}
  other {# G}
}`,
  KGM_short: `{value, plural,
  =0 {0 KG}
  one {1 KG}
  other {# KG}
}`,
  TNE_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  PS_short: `{value, plural,
  =0 {0 PSI}
  one {1 PSI}
  other {# PSI}
}`,
  MPA_short: `{value, plural,
  =0 {0 MPa}
  one {1 MPa}
  other {# MPa}
}`,
  C56_short: `{value, plural,
  =0 {0 Newtons Per Square Millimeter}
  one {1 Newton Per Square Millimeter}
  other {# Newtons Per Square Millimeter}
}`,
  C62_short: `{value, plural,
  =0 {0 One}
  one {1 One}
  other {# One}
}`,
  NL_short: `{value, plural,
  =0 {0 Loads}
  one {1 Load}
  other {# Loads}
}`,
  MT_short: `{value, plural,
  =0 {0 Mats}
  one {1 Mat}
  other {# Mats}
}`,
  P1_short: `{value, plural,
  =0 {0 %}
  one {1 %}
  other {# %}
}`,
  CEL_short: `{value, plural,
  =0 {0 C}
  one {1 C}
  other {# C}
}`,
  FAH_short: `{value, plural,
  =0 {0 F}
  one {1 F}
  other {# F}
}`,
  BLL_short: `{value, plural,
  =0 {0 BBL}
  one {1 BBL}
  other {# BBL}
}`,
  CLT_short: `{value, plural,
  =0 {0 cL}
  one {1 cL}
  other {# cL}
}`,
  DLT_short: `{value, plural,
  =0 {0 dL}
  one {1 dL}
  other {# dL}
}`,
  GLI_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  '2U_short': `{value, plural,
  =0 {0 Megagrams}
  one {1 Megagram}
  other {# Megagrams}
}`,
  SA_short: `{value, plural,
  =0 {0 Sacks}
  one {1 Sack}
  other {# Sacks}
}`,
  '60_short': `{value, plural,
  =0 {0 Percent Weight}
  one {1 Percent Weight}
  other {# Percent Weight}
}`,
  BG_short: `{value, plural,
  =0 {0 Bags}
  one {1 Bag}
  other {# Bags}
}`,
  SEC_short: `{value, plural,
  =0 {0 SEC}
  one {1 SEC}
  other {# SEC}
}`,
  '64_short': `{value, plural,
  =0 {0 Pounds per square inch - Gauge}
  one {1 Pound per square inch - Gauge}
  other {# Pounds per square inch - Gauge}
}`,
  SP_short: `{value, plural,
  =0 {0 Shelf Packages}
  one {1 Shelf Package}
  other {# Shelf Packages}
}`,
  MMK_short: `{value, plural,
    =0 {0 Kvadratmillimeter}
    one {1 kvadratmillimeter}
    other {# kvadratmillimeter}
  }`,
  MTN_short: `{value, plural,
  =0 {0 MT}
  one {1 MT}
  other {# MT}
}`,
  GT_short: `{value, plural,
  =0 {0 GT}
  one {1 GT}
  other {# GT}
}`,
  '/NL_short': `{value, plural,
=0 {0 /Load}
one {1 /Load}
other {# /Load}
}`,
  '/EA_short': `{value, plural,
=0 {0 /EA}
one {1 /EA}
other {# /EA}
}`,
  '/H/ + h_short': `{value, plural,
=0 {0 /Hour}
one {1 /Hour}
other {# /Hour}
}`,
  '/YQ/ + YDQ_short': `{value, plural,
=0 {0 /CY}
one {1 /CY}
other {# /CY}
}`,
  '/MQ/ + MTQ_short': `{value, plural,
=0 {0 /M3}
one {1 /M3}
other {# /M3}
}`,
  '/MN/ + MIN_short': `{value, plural,
=0 {0 /MIN}
one {1 /MIN}
other {# /MIN}
}`,
  '/MT_short': `{value, plural,
=0 {0 /M}
one {1 /M}
other {# /M}
}`,
  DSG_short: `{value, plural,
=0 {0 Dosage}
one {1 Dosage}
other {# Dosage}
}`,
  '/C_short': `{value, plural,
=0 {0 /C}
one {1 /C}
other {# /C}
}`,
  '64_2_short': `{value, plural,
=0 {0 Specific}
one {1 Specific}
other {# Specific}
}`,
  mixIdLabel: 'Blanding / Beskrivelse',
  orderedQtyLabel: 'Bestilt mængde',
  loadedQtyLabel: 'Læsset mængde',
  addedByCustomer: 'Tilføjet af kunde',
  PRINTED: 'Tildelt',
  LOADING_STARTED: 'Start læsning',
  LOADING_COMPLETE: 'Læsset',
  TO_JOB: 'Fra fabrik',
  ARRIVE_JOB: 'Ankomst Byggeplads',
  UNLOADING: 'Start aflæsningstid',
  END_UNLOADING: 'Slut aflæsningstid',
  LEAVE_JOB: 'Fra byggeplads',
  IN_YARD: 'I gården',
  PUMPING_STARTED: 'Start pumpen',
  PUMPING_COMPLETE: 'Afslut pumpe',
  READY_TO_PUMP: 'Klar til at pumpe',
  FIRST_WCCONTACT: 'Tid V/C kontakt',
  plantName: 'Fabriks navn',
  plantAddress: 'Fabriks adresse',
  loadingScheduleStart: 'Planlagt læssetid',
  deliveryAddress: 'Leveringsadresse',
  phoneNumber: 'Telefonnummer',
  unloadingScheduleStart: 'Planlagt aflæsnings tidspunkt',
  agreedUnloading: 'Bestilt aflæsningstid',
  deliveryInformation: 'Leverings information',
  next: 'Næste',
  material: 'Materiale',
  loadingPoint: 'Læssested',
  unloadingPoint: 'Aflæsningssted',
  comment: 'Kommentarer',
  sapDeliveryNumber: 'Følgeseddelsnummer',
  salesOrderNumber: 'Ordernummer og orderrække',
  wcDateTime: 'Dato og Tid V/C kontakt',
  po: 'Sags nr.',
  orderedByName: 'Bestillers navn',
  orderedByPhone: 'Bestillers telefonnummer',
  resourceId: 'Køretøj ID',
  totalLoadedQty: 'Total læsset mængde inkl.dette læs',
  spacing: 'Interval, min.',
  deliveryFlow: 'Gearinger flow, m3/t',
  timeOnSite: 'Bestilt aflæsningstid, min.',
  salesText: 'Salgs tekst',
  strengthClass: 'Styrkeklasse ',
  environmentExposure: 'Miljøpåvirkning',
  metExposureClasses: 'Eksponeringsklasse',
  dmax: 'Dmax',
  standardCert: 'Standard',
  workabilityTarget: 'Konsistens',
  stoneQuality: 'Stenkvalitet',
  sandQuality: 'Sandkvalitet',
  targetWC: 'Tilstræbt V/C forh.',
  controlClass: 'Kontrolklasse',
  chlorideClass: 'Chloridklasse',
  typeAndStrength1: 'Cement 1',
  typeAndStrength2: 'Cement 2',
  typeOfAddition: 'Tilsætningsmaterialer',
  typeOfAdmixture: 'Tilsætningsmiddel',
  orderedTemp: 'Bestilt temperatur',
  fibers1: 'Type og mængde af fiber 1',
  fibers2: 'Type og mængde af fiber 2',
  calculatedMaturityTime: 'Modenhedstid overskrides kl',
  mixIdentity: 'Bland identitet',
  grade: 'karakter',
  mixType: 'Bland type',
  maxAggSize: 'Max AGG -størrelse',
  maxAggType: 'Max AGG Type',
  cementType: 'Cementtype',
  admixtures: 'Blandinger',
  addmixDescription: 'AddMix -beskrivelse',
  slump: 'Falder',
  minCementContent: 'Min cementindhold',
  maxWatCemRatio: 'Maksimalt vand/cementforhold',
  maxWaterToAdd: 'Maksimalt vand for at tilføje',
  mortarMinWorkingLife: 'Mørtel min arbejdsliv',
  code: 'Kode',
  dcClass: 'dcClass',
  chloride: 'Chlorid',
  signedByHanson: 'Underskrevet af Hanson',
  warmStamp: 'Varmt stempel',
  coldStamp: 'Koldt stempel',
  containsSikatard: 'Indeholder Sikatard',
  containsDelvo: 'Indeholder Delvo',
  generalDelivery: 'Generel leverings information',
  materialInfo: 'Materiale information',
  water: 'Vand',
  materialService: 'Materiale/Service',
  returnedMaterial: 'Returbeton',
  returnedConcrete: 'Returneret beton',
  waterAdded: 'Vand tilsat',
  concreteOnTruck: 'Mængde i køretøj',
  concreteOnTruckUom: 'Standard kapacitet køretøj',
  reason: 'Årsag',
  customerAddedWater: 'Kunden har tilsat vand',
  addWater: 'Tilsæt vand',
  addedAt: 'Tilføjet ved ',
  addMaterialService: 'Tilsæt materiale/service',
  customerAddedProduct: 'Kunden tilsat produkt',
  addReturnedMaterial: 'Tillæg Returneret beton',
  leftover: 'Resterende vægt',
  submitAcceptance: 'Acceptere',
  accepted: 'Accepteret',
  rejected: 'Afvis belastning',
  customerAcceptance: 'Kundens accept',
  finalize: 'Afslut',
  materialSummary: 'Summation',
  mix: 'Blanding',
  ordered: 'Bestilt',
  loaded: 'Leveringslæs',
  totalOrdered: 'Total order mængde',
  loadedOrderQuantity: 'Læsset order mængde',
  loadedOrder: 'Læsset order',
  driverComment: 'Chauffør kommentarer',
  customerComment: 'Kunde kommentarer',
  editComment: 'Ret kommentarer',
  addComment: 'Tilføj kommentar',
  additionalInfo: 'Ekstra information',
  onBoard: 'Læsset',
  driver: 'Chauffør',
  commentInstruction: 'Kommentar felt',
  signatureInstruction: 'Underskrift',
  customerSignature: 'Kundens underskrift',
  general: 'Generelt',
  status: 'Status',
  updateStatusTime: 'Opdatere status tid',
  delete: 'Slet',
  cancel: 'Annullere',
  back: 'Tilbage',
  add: 'Tilføj',
  ok: 'Okay',
  decline: 'Nedgang',
  clear: 'Klar',
  reset: 'Nulstil ',
  done: 'Færdig',
  lastRefresh: 'Sidste opdatering',
  refreshEnabled: 'Aktivere opdatering',
  id: 'ID',
  name: 'Navn',
  invoiceable: 'Fakturerings klar',
  provideSignature: 'Underskrift',
  ticket: 'Følgeseddel',
  details: 'Detaljer',
  qrCode: 'QR',
  driverAcceptanceReason: 'Årsag',
  driverAcceptance: 'Chauffør godkendelse',
  'reason-code': 'Årsagskode',
  'add-reason-code': 'Tilføj årsagskode',
  'qr-code': 'QR Kode',
  'unloading-method': 'Aflæsningstype',
  'add-unloading-method': 'Tilføj Aflæsningstype',
  'driver-comment': 'Chauffør kommentar',
  'add-driver-comment': 'Tilføj Chauffør kommentarer',
  'driver-sellable': 'Driver soldbar',
  vehicle: 'Køretøjer',
  'add-vehicle': 'Tilføj køretøj',
  'vehicle-type': 'Køretøjs type',
  vehicleTypes: 'Køretøjs typer',
  vehicleType: 'Køretøjs type',
  'add-vehicle-type': 'Tilføj køretøjs type',
  countries: 'Lande',
  country: 'Jord',
  type: 'Type',
  uom: 'Måleenhed',
  mandatory: 'Obligatorisk',
  qrTemplate: 'QR-kode',
  includeMixInfo: 'Inkluderer materialeinformation i QR-kode',
  includeTicketEvents: 'Inkluderer følgeseddelshændelser i QR-kode',
  quantityField: 'Aktivere mængde felt',
  concreteOnTruckField: 'Aktivere betonfelt på bilen',
  materialOnTruckField: 'Aktivere materialefelt på bilen',
  timeField: 'Aktivere tids felt',
  reasonField: 'Aktivere årsags felt ',
  driverInstructions: 'Chauffør instruktioner',
  waitingForTicket: 'Venter på billet...',
  createTestTicketForTruck: 'Opret testbillet til lastbil',
  exceedsLoadedQuantity: 'Værdien kan ikke overskride blandede mængde {loadedQuantity}',
  maxQuantity: 'Maximal mængde',
  maxQuantityError: 'Mængde kan ikke overskride defineret max mængde {maxQty}',
  maxValueError: 'Værdi kan ikke overstige defineret maksimalt {maxQty}',
  minValueError: 'Værdi kan ikke være mindre end defineret minimum af {minQty}',
  notAuthorized: 'Ikke Autoriseret',
  enterTruckNumber: 'Indsæt køretøjs nummer',
  setTruckNumber: 'Angiv køretøjs nummer',
  commentList: 'Kommentarliste',
  list: 'Liste',
  gridPreferences: 'Præference grid',
  loadingUnloadingPoints: 'Læsse-/aflæsningssted',
  activity: 'Aktivitet',
  crn: 'Crn',
  update: 'Opdatering',
  CONCRETE: 'Beton',
  WATER: 'Vand',
  ADDITIVE: 'Additiver',
  'submit-signature-button': 'Acceptere',
  'cancel-signature-button': 'Annullere',
  uomCode: 'Standard måleenhed',
  'product-type': 'Produkt type',
  'add-product-type': 'Tilføj produkt type',
  'add-product': 'Tilføj produkt',
  'setup_driver-comment': 'Chauffør kommentar',
  setup_product: 'Produkter',
  'setup_product-type': 'Produkt type',
  'setup_qr-code': 'QR Kode',
  'setup_reason-code': 'Årsags koder',
  'setup_unloading-method': 'Aflæsningstype',
  'setup_entity-mt': 'Firma',
  setup_vehicle: 'Køretøj',
  'setup_vehicle-type': 'Køretøjs type',
  'setup_data-retentions': 'Data opbevaring',
  'add-data-retentions': 'Tilføj data opbevaring',
  'data-retentions': 'Data opbevaring',
  setup_carriers: 'Opsætning af udbyder',
  'add-carriers': 'Tilføj udbyder',
  carriers: 'Udbydere',
  'setup_concrete-classes': 'Materiel overholdelse',
  'add-concrete-classes': 'Tilføj materiel overholdelse',
  'concrete-classes': 'Materiel overholdelse',
  'setup_forms-and-waivers': 'Formularer og undtagelser',
  'add-forms-and-waivers': 'Tilføj formularer og undtagelser',
  'forms-and-waivers': 'Formularer og undtagelser',
  companyFleet: 'Vognmænd',
  category: 'Kategori',
  interval: 'Interval',
  orders: 'Ordrer og følgesedler',
  '30days': '30 dage',
  '60days': '60 dage',
  '90days': '90 dage',
  '1year': '1 år',
  '3years': '3 år',
  waterMandatory: 'Vand Obligatorisk',
  qrCodeTemplate: 'QR Kode Master',
  waterConfig: 'Valgbart felt',
  waterProduct: 'Vand',
  waterProductType: 'Vandprodukt type',
  defaultWaterProduct: 'Standard vandprodukt',
  invalidTemplate: 'Ugyldig skabelon',
  siteAddress: 'Byggeplads Adresse',
  siteContactName: 'Kontaktperson Byggeplads',
  siteContactPhoneNumber: 'Kontaktperson Byggeplads Telefon',
  'clear-signature-button': 'Slet underskrift',
  customerCommentEnabled: 'Aktiver Kunde kommentar',
  driverCommentEnabled: 'Aktiver Chauffør kommentar',
  driverCommentMaxCharacters: 'Max længde',
  customerCommentMaxCharacters: 'Max længde',
  timeZone: 'Tidszone',
  driverDidNotAdd: 'Chauffør ej tilsat',
  maxLineItems: 'Max antal ordrelinjer',
  oneLineItem: 'Mindst en ordrelinje',
  signatureSiteContact: 'Kundenavn',
  ticketEvents: 'Følgeseddel hændelse',
  hideEvent: 'Skjul hændelse',
  editable: 'Redigerbar',
  ticketDetail: `Følgeseddels detaljer #{value}`,
  statusTimes: 'Status tider',
  materialAndDeliveryInfo: 'Matriale- og leverings information',
  customerRejection: 'Afvist af kunde',
  'submit-rejection-button': 'Godkend afvisning',
  accept: 'Acceptere',
  reject: 'Afvis belastning',
  signature: 'Underskrift',
  'no-signature': 'Ingen underskrift',
  unFinalizePassword: 'Ej færdiggjort',
  waterInWorkflow: 'Aktiver Vandtrin i Workflow',
  multicountry: 'Flere Lande',
  timestampAfterSequence: 'Advarsel: Tid skal ikke kunne være før tidligere tidsstempel',
  timestampBeforeSequence: 'Advarsel: Tid skal ikke kunne være efter næste tidsstempel',
  noRestQuantity: 'Ingen returneret materiale',
  returnedMaterialMandatory: 'Må genbruge beton',
  driverCommentMaxChars: 'Længden af karakter kan ikke overskride {maxLength} karakter.',
  QrCodeEnabled: 'Aktivere QR kode',
  QrMethod_linkBased: 'Link baseret',
  QrMethod_contentBased:
    'Indholdsbaseret, BEMÆRK: Indholdsbaserede QR-koder har tegnbegrænsninger, der kan forårsage problemer ud over 1024 tegn.',
  QrMethod_contentBasedWarning:
    'BEMÆRK: Denne QR-kode har {amount} tegn, som muligvis ikke kan læses af nogle telefoner.',
  returnedMaterialInWorkflow: 'Aktivere returbeton i flow',
  materialServiceInWorkflow: 'Aktiver Materiale/Service Trin i Workflow',
  totalPumpedQuantity: 'Total pumpet mængde',
  concrete: 'Beton',
  materialComplianceEnabled: 'Aktivere materiale overholdelse',
  pumpStatusTimes: 'Pumpe status tider',
  pollingInterval: 'Afstemnings interval',
  tenSecs: 'Interval skal være mindst 10 sekunder',
  materialCompliance: 'Materiel overholdelse',
  consistenceValue: 'Konsistens - Falder',
  consistenceIsCompliant: 'Konsistens overholder BS8500-2',
  airContentValue: 'Luftindhold',
  airContentIsCompliant: 'Luftindhold overholder BS8500-2',
  consistenceIsCompliantShort: 'Konsistens overholder',
  airContentIsCompliantShort: 'Luftindhold overholder',
  compliance: 'Materiel overholdelse af BS8500-2',
  termsAndConditionsEnabled: 'Aktivér vilkår og betingelser formular',
  termsAndConditions: 'Vilkår og betingelser',
  damageWaiverEnabled: 'Aktivér formular til afkald på skader',
  damageWaiver: 'Skader undtagelse',
  damageWaiverAcknowledged: 'Skadefritagelse erkendt',
  dynamicWaiver: 'Dynamisk undtagelse',
  dynamicWaiverEnabled: 'Aktivér formular til dynamisk afkald',
  ticketListEnabled: 'Aktivér billetliste',
  clearTicketAfterSubmission: 'Ryd billet efter indsendelse',
  keepTicketOpenAfterSubmission: 'Hold billet åben efter indsendelse',
  displayTicketList: 'Vis billetliste',
  supportedLanguages: 'Understøttede sprog',
  allow: 'Give lov til',
  default: 'Standard',
  translations: 'Oversættelser',
  family_name: 'Efternavn',
  given_name: 'Fornavn',
  email: 'E-mail',
  role: 'Rolle',
  inviteUser: 'Inviter bruger',
  carrier: 'Udbyder',
  emailOrPhoneNumber: 'E-mail',
  usersEditor: 'Bruger editor',
  verifyPassword: 'Bekræft password',
  verificationCode: 'Kode',
  register: 'Registrere',
  registerInstructions: 'Udfyld formularen',
  'password-requirements':
    'Password skal være på min 8 karakterer, indeholde 1 stort bogstav, 1 lille bogstav 1 nummer og et tegn',
  passwordMinCharacters: 'Password skal være på min 8 karakterer',
  passwordMaxCharacters: 'Password er for langt',
  passwordLowerCase: 'Password skal indeholde små bogstaver',
  passwordUpperCase: 'Password skal indeholde mindst 1 stort bogstav',
  passwordNumber: 'Password skal indeholde mindst et tal',
  passwordSpecialCharacter: 'Password skal indeholde mindst 1 tegn',
  passwordsDontMatch: 'Password matcher ikke',
  success: 'Det gik som smurt',
  'registration-success': 'Din oprettelse var succesfuld',
  'post-registration-instructions': 'Du er nu registreret. Du kan nu logge ind',
  createDate: 'Dato',
  customerPo: 'Rekvisitions nr.',
  specification: 'Standard',
  description: 'Beskrivelse',
  classType: 'Klassetype',
  minimumValue: 'Minimumsværdi',
  maximumValue: 'Maksimal værdi',
  TrackIt: 'TrackIt',
  trackIt3PContext: 'TrackIt 3p-kontekst',
  inviteLanguage: 'Inviter sprog',
  truckNumber: 'Lastbilnummer',
  adverseTemperature:
    'Omgivelsestemperatur på blandingstidspunktet var sådan, at vi ikke kan garantere, at betonen vil være i temperaturområdet på 5-35oC som specificeret i EN 206.',
  environmentalNote:
    'Denne belastning indeholder et miljømæssigt, der ikke overstiger 0,03% efter betonen. Vand indeholdende dette materiale til fremstilling af beton vil overholde BS EN 1008.',
  unloadingMethodEnabled: 'Aktiver aflæsningsmetode',
  warrantyVoided: 'Certifisering Bortfalder',
  isCertificationLossTriggerForReason: 'Forårsager certificeringstab',
  isCertificationLossTriggerForProduct: 'Forårsager certificeringstab',
  materialWarrantyVoidedEnabled:
    'Når produktet til tab af certificering eller produkt & årsagskode anvendes, vis en note om tab af certificering ved underskriftstidspunktet.',
  'setup_templates-mt': 'Skabeloner',
  copyFrom: 'Kopi fra...',
  users: 'Brugere',
  setup: 'Opsætning',
  copy: 'Kopi',
  content: 'Indhold',
  WARNINGS: 'Advarsler',
  TERMS: 'Betingelser',
  DAMAGE: 'Skade',
  ADDITIONALWAIVER: 'Yderligere undtagelse',
  DYNAMICWAIVER: 'Dynamisk undtagelse',
  source: 'Sonde',
  sensorUnable: 'Sensor er ikke i stand til at bestemme resterende mængde.',
  PROBE: 'Sonde',
  DRIVER: 'Chauffør',
  DRS: 'DRS',
  waterReleaseFormEnabled: 'Aktivér formularen til frigivelse af vand',
  waterRelease: 'Vandfrigivelse',
  waterReleaseAcknowledged: 'Vandudslip anerkendt',
  'assessment-risk': 'Risikovurdering',
  'setup_assessment-risk': 'Risikovurdering',
  'add-assessment-risk': 'Tilføj risikovurdering',
  enterRecipientsButton: 'Indtast modtager(e)',
  recipientsInstructions: 'Indtast e-mail, adskilt af et komma',
  enter: 'Gå ind',
  invalidEmailAddress: 'ugyldig emailadresse',
  personalDataPolicy:
    'Ved at indtaste personlige data i denne formular bekræfter du, at du har de nødvendige tilladelser til at dele disse personlige data',
  subcategory: 'Underkategori',
  isRequired: 'Er påkrævet',
  riskAssessmentEnabled: 'Aktivér risikovurdering',
  riskAssessment: 'Risikovurdering',
  yes: 'Ja',
  no: 'Ingen',
  sourceOptionsSensors: 'Kildeindstillinger (sensorer)',
  sourceDcm: 'DCM',
  sourceProbe: 'Sonde',
  availableCustomValues: 'Tilgængelige brugerdefinerede værdier',
  ERR_CODE__DUPLICATE: 'Duplikere',
  ERR_CODE__DEFAULT_VALUE: 'Der er allerede en standardværdi',
  ERR_CODE__EXISTING_FORM: 'Der er allerede en aktiv formular for dette køretøj og type',
  availableValues: 'Tilgængelige værdier',
  pump: 'Pumpe',
  DanskName: 'Dansk navn',
  GermanName: 'Tysk navn',
  EnglishName: 'Engelsk navn',
  BritainName: 'Storbritannien navn',
  SpanishName: 'Spansk navn',
  FrenchName: 'Fransk navn',
  NederlandsName: 'Nederlands navn',
  NorskName: 'Norsk navn',
  SvenskaName: 'Svenska navn',
  ItalianName: 'Italiensk navn',
  PolishName: 'Polsk navn',
  DanskNameDefault: 'Dansk navn (standard)',
  GermanNameDefault: 'Tysk navn (standard)',
  EnglishNameDefault: 'Engelsk navn (standard)',
  BritainNameDefault: 'Storbritannien navn (standard)',
  SpanishNameDefault: 'Spansk navn (standard)',
  FrenchNameDefault: 'Fransk navn (standard)',
  NederlandsNameDefault: 'Nederlands navn (standard)',
  NorskNameDefault: 'Norsk Name (standard)',
  SvenskaNameDefault: 'Svenska navn (standard)',
  ItalianNameDefault: 'Italiensk navn (standard)',
  PolishNameDefault: 'Polsk navn (standard)',
  DanskDescription: 'Dansk beskrivelse',
  GermanDescription: 'Tysk beskrivelse',
  EnglishDescription: 'Engelsk beskrivelse',
  BritainDescription: 'Storbritannien beskrivelse',
  SpanishDescription: 'Spansk beskrivelse',
  FrenchDescription: 'Fransk beskrivelse',
  NederlandsDescription: 'Nederlands beskrivelse',
  NorskDescription: 'Norsk -beskrivelse',
  SvenskaDescription: 'Svenska Beskrivelse',
  ItalianDescription: 'Italiensk Beskrivelse',
  PolishDescription: 'Polsk beskrivelse',
  DanskDescriptionDefault: 'Dansk beskrivelse (standard)',
  GermanDescriptionDefault: 'Tysk beskrivelse (standard)',
  EnglishDescriptionDefault: 'Engelsk beskrivelse (standard)',
  BritainDescriptionDefault: 'Storbritannien Beskrivelse (standard)',
  SpanishDescriptionDefault: 'Spansk beskrivelse (standard)',
  FrenchDescriptionDefault: 'Fransk beskrivelse (standard)',
  NederlandsDescriptionDefault: 'Nederlands beskrivelse (standard)',
  NorskDescriptionDefault: 'Norsk beskrivelse (standard)',
  SvenskaDescriptionDefault: 'Svenska beskrivelse (standard)',
  ItalianDescriptionDefault: 'Italiensk beskrivelse (standard)',
  PolishDescriptionDefault: 'Polsk beskrivelse (standard)',
  DanskContent: 'Dansk indhold',
  GermanContent: 'Tysk indhold',
  EnglishContent: 'Engelsk indhold',
  BritainContent: 'Storbritanniens indhold',
  SpanishContent: 'Spansk indhold',
  FrenchContent: 'Fransk indhold',
  NederlandsContent: 'Nederlands indhold',
  NorskContent: 'Norsk -indhold',
  SvenskaContent: 'Svenska -indhold',
  ItalianContent: 'Italiensk -indhold',
  PolishContent: 'Polsk indhold',
  DanskContentDefault: 'Dansk indhold (standard)',
  GermanContentDefault: 'Tysk indhold (standard)',
  EnglishContentDefault: 'Engelsk indhold (standard)',
  BritainContentDefault: 'Storbritannien indhold (standard)',
  SpanishContentDefault: 'Spansk indhold (standard)',
  FrenchContentDefault: 'Fransk indhold (standard)',
  NederlandsContentDefault: 'Nederlands indhold (standard)',
  NorskContentDefault: 'Norsk -indhold (standard)',
  SvenskaContentDefault: 'Svenska -indhold (standard)',
  ItalianContentDefault: 'Italiensk -indhold (standard)',
  PolishContentDefault: 'Polsk indhold (standard)',
  DanskCategory: 'Dansk kategori',
  GermanCategory: 'Tysk kategori',
  EnglishCategory: 'Engelsk kategori',
  BritainCategory: 'Storbritanniens kategori',
  SpanishCategory: 'Spansk kategori',
  FrenchCategory: 'Fransk kategori',
  NederlandsCategory: 'Nederlands kategori',
  NorskCategory: 'Norsk -kategori',
  SvenskaCategory: 'Svenska -kategori',
  ItalianCategory: 'Italiensk -kategori',
  PolishCategory: 'Polsk kategori',
  DanskCategoryDefault: 'Dansk kategori (standard)',
  GermanCategoryDefault: 'Tysk kategori (standard)',
  EnglishCategoryDefault: 'Engelsk kategori (standard)',
  BritainCategoryDefault: 'Storbritanniens kategori (standard)',
  SpanishCategoryDefault: 'Spansk kategori (standard)',
  FrenchCategoryDefault: 'Fransk kategori (standard)',
  NederlandsCategoryDefault: 'Nederlands kategori (standard)',
  NorskCategoryDefault: 'Norsk -kategori (standard)',
  SvenskaCategoryDefault: 'Svenska -kategori (standard)',
  ItalianCategoryDefault: 'Italiensk -kategori (standard)',
  PolishCategoryDefault: 'Polsk kategori (standard)',
  DanskSubcategory: 'Dansk underkategori',
  GermanSubcategory: 'Tysk underkategori',
  EnglishSubcategory: 'Engelsk underkategori',
  BritainSubcategory: 'Storbritannien underkategori',
  SpanishSubcategory: 'Spansk underkategori',
  FrenchSubcategory: 'Fransk underkategori',
  NederlandsSubcategory: 'Nederlands underkategori',
  NorskSubcategory: 'Norsk -underkategori',
  SvenskaSubcategory: 'Svenska underkategori',
  ItalianSubcategory: 'Italiensk underkategori',
  PolishSubcategory: 'Polsk underkategori',
  DanskSubcategoryDefault: 'Dansk underkategori (standard)',
  GermanSubcategoryDefault: 'Tysk underkategori (standard)',
  EnglishSubcategoryDefault: 'Engelsk underkategori (standard)',
  BritainSubcategoryDefault: 'Storbritannien underkategori (standard)',
  SpanishSubcategoryDefault: 'Spansk underkategori (standard)',
  FrenchSubcategoryDefault: 'Fransk underkategori (standard)',
  NederlandsSubcategoryDefault: 'Nederlands underkategori (standard)',
  NorskSubcategoryDefault: 'Norsk underkategori (standard)',
  SvenskaSubcategoryDefault: 'Svenska underkategori (standard)',
  ItalianSubcategoryDefault: 'Italiensk underkategori (standard)',
  PolishSubcategoryDefault: 'Polsk underkategori (standard)',
  priorSignatures: 'Tidligere underskrifter',
  damageWaiverSignature: 'Skadefritagelsessignatur',
  waterReleaseSignature: 'Vandfrigivelsessignatur',
  errors,
  themes,
  isPDFRecipientListEnable: 'Aktiveret PDF-modtagerliste på kundesignaturskærm',
  isRejectLoadsEnable: 'Aktivér afvisning af læs efter ankomststed',
  rejectLoad_returnedMaterial: 'Workflow-knap til returneret materiale',
  rejectLoad_returnedAndFinalize: 'Knapper til returnering af materiale og færdiggørelse af workflow',
  driverRejection: 'Afvis belastning',
  submitRejection: 'Send Afvis',

  costAndPaymentsSummary: 'Oversigt over omkostninger og betalinger',
  subtotal: 'Subtotal',
  tax: 'Skat',
  remainingBalance: 'Resterende saldo',
  paymentAmount: 'Betalingsbeløb',
  total: 'Total',
  paymentMethod: 'Betalingsmetode',
  paymentProducts: 'Produkter',
  unitPrice: 'Pris per stk',
  amount: 'Beløb',
  otherPaymentMethod: 'Anden betalingsmetode',
  method: 'Metode',
  paymentOptions: 'Betalingsmuligheder',
  typeAdditionalInfo: 'Indtast yderligere information her',
  COD: 'Kontant ved levering',
  AMEX: 'American Express',
  CASH: 'Kontanter',
  CHECK: 'Kontrollere',
  'CREDIT CARD': 'Kreditkort',
  ACCOUNT: 'Åben konto',
  UNKNOWN: 'Ukendt',
  remainingMaterial: 'Resterende materiale',
  splitLoad: 'Split belastning',
  isEnablePaymentAndCostWorkflows: 'Aktivér betalings- og omkostningsarbejdsprocesser',
  airTempValue: 'Lufttemperatur',
  concreteTempValue: 'Betontemperatur',
  cylinderNumber: 'Cylindernummer',
  materialOnTruck: 'Materiale på lastbil',
  waterLocation: 'Wasserstandort',

  dotWorkflow: 'Batch detaljer',
  inspectorSignature: 'Inspektørunderskrift',
  producerSignature: 'Producentunderskrift',
  addedItems: 'Tilføjede elementer',
  producerSignatureContact: 'Producentnavn',
  inspectorSignatureContact: 'Inspektørnavn',
  waterAtPlant: 'Vand på anlægget (gl)',
  waterAtJobsite: 'Vand på arbejdsstedet (gl)',
  totalActualWater: 'Samlet faktisk vand',
  timeDischarged: 'Afladningstid',
  ingredient: 'Ingrediens',
  mcFac: 'MCFac',
  absFac: 'ABSFAC',
  od: 'OD',
  abs: 'ABS',
  ssd: 'SSD',
  freeMst: 'Fri Fugt',
  cydsTarg: 'CYDS Mål',
  target: 'Mål',
  actual: 'Faktisk',
  freWat: 'Fri Vand',
  trim: 'Trim',
  batchInformation: 'Batch Information',
  numberOfBatches: 'Antal batches',
  totalWater: 'Total Vand',
  designWater: 'Design Vand',
  toAdd: 'At Tilføje',
  designWC: 'Design W/C',
  waterCement: 'Vand / Cement',
  waterInTruck: 'Vand i Lastbil',
  adjustWater: 'Juster Vand',
  trimWater: 'Trim Vand',
  plantWeather: 'Plante Vejr',
  rh: 'RH',
  observationTime: 'Observationstid',
  address: 'Adresse',
  plant: 'Plante',
  sp: 'S.P. #',
  beginTime: 'Starttid',
  mixDesc: 'Blandingsbeskrivelse',
  qtyOder: 'Antal Bestilling',
  poTag: 'PO #',
  bridge: 'Bro #',
  volume: 'Volumen',
  truckTag: 'Lastbil #',
  qtyDelivered: 'Antal Leveret',
  qtyOrder: 'Antal Bestilling',
  job: 'Job #',
  batchRecord: 'Batch Optagelse',
  mixID: 'Blandings ID',
  loads: 'Laster',
  isDot: 'Er DOT',
  tolStatus: 'TOL Status',
  lastUpdate: 'Sidste opdatering',
  driverClickedFinalize: 'Chauffør klikkede på færdiggørelse',
  autoSubmit: 'Auto Indsend',
};
